<template>
  <div>
    <ul class="tabs flex flex-wrap">
      <slot />
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.tabs {
  $border-size: 2px;
  $link-color: #6a7785;
  $active-link-color: #32384e;

  font-size: 18px;
  margin-bottom: -8px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  :deep(li) {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    margin-right: 20px;
    padding-bottom: 8px;
    position: relative;
    color: $link-color;

    &:hover {
      color: $active-link-color;
    }

    &:before {
      background: mix($main-gray-color, white, 10%);
      bottom: 0;
      content: '';
      display: block;
      height: $border-size;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &.active {
      color: $active-link-color;
      pointer-events: none;

      &:before {
        background: $main-blue-color;
      }
    }

    .count {
      background: mix($main-gray-color, white, 60%);
      border-radius: 4px;
      color: white;
      display: inline-block;
      font-size: .75em;
      font-weight: 700;
      line-height: 1em;
      margin-left: 8px;
      padding: 4px 8px;
      white-space: nowrap;
    }
  }
}
</style>
